import { BlackBox } from "@wayflyer/blackbox-fe";
import {
  ErrorComponentProps,
  Flex,
  FlyErrorComponent,
  FlyUIProvider,
  Link,
  Logo,
  Text,
} from "@wayflyer/flyui";
import { FormattedMessage, useIntl } from "react-intl";

type CodeOwner = "platform";

export const displayOwners: Record<CodeOwner, string> = {
  platform: "S02UJRQUTRA", // @@platform-team
};

export const AuthPathOwners: Record<string, string> = {
  "/:wildcard*/": "platform",
};

export const ErrorComponent = (props: ErrorComponentProps) => {
  return (
    <Flex
      direction="column"
      padding="6"
      minHeight="100vh"
      minWidth="100%"
      gap="16"
    >
      <Link url="/">
        <Flex maxWidth="200px">
          <Logo type="auth" />
        </Flex>
      </Link>
      <FlyErrorComponent {...props} />
    </Flex>
  );
};

type BlackboxProviderProps = {
  children: React.ReactNode;
};

export const BlackboxProvider = ({ children }: BlackboxProviderProps) => {
  const intl = useIntl();

  return (
    <>
      <BlackBox
        FallbackComponent={({ resetErrorBoundary }) => (
          <FlyUIProvider theme="wayflyer">
            <ErrorComponent
              {...{
                title: intl.formatMessage({
                  id: "auth: error unknown default title",
                  defaultMessage: "Sorry, we’ve encountered an error",
                }),
                message: (
                  <FormattedMessage
                    id="auth: error unknown default message"
                    defaultMessage="<p>We’re working hard to get this fixed. Please go back or try refreshing the page. For more information contact our support team.</p>"
                    values={{
                      p: (chunks) => <Text>{chunks}</Text>,
                    }}
                  />
                ),
                primaryAction: {
                  content: intl.formatMessage({
                    id: "Go back",
                    defaultMessage: "Go back",
                  }),
                  onAction: resetErrorBoundary,
                },
              }}
            />
          </FlyUIProvider>
        )}
      >
        {children}
      </BlackBox>
    </>
  );
};
