import { Text } from "@wayflyer/flyui";
import { useIntl } from "react-intl";
import { styled } from "styled-components";

import { ErrorComponent } from "../blackbox/BlackboxProvider";

const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

interface FullScrenMessageProps {
  message: string;
}

export const FullScreenMessage = (props: FullScrenMessageProps) => {
  const intl = useIntl();
  const backButton = {
    content: intl.formatMessage({
      id: "Go back",
      defaultMessage: "Go back",
    }),
    onAction: () => {
      window.location.pathname = "/authn/logout";
    },
  };
  return (
    <FullScreenContainer>
      <ErrorComponent
        {...{
          title: intl.formatMessage({
            id: "auth: error unknown default title",
            defaultMessage: "Sorry, we’ve encountered an error",
          }),
          message: <Text>{props.message}</Text>,
          primaryAction: backButton,
        }}
      />
    </FullScreenContainer>
  );
};
